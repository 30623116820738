export const doctorData =  [
    {
        id: 1,
        name:"Dr. Akinoso Olujimi Coker"
    },
    {
        id: 2,
        name:"Dr. Olatunbosun Ayokunle Oke"
    },
    {
        id: 3,
        name:"Dr. Chinedu Clinton Anyadike"
    },
    {
        id: 4,
        name:"Dr. Jerome Eyaofun Ohene"
    },
]